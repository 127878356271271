































































































































































import {
    Component,
    Vue
} from 'vue-property-decorator';

import _ from 'lodash'
import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {

    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})
export default class Home extends Vue {
    private CURRNT_AGENGY: number = 0;
    private AGENCY_DATA: any = null;
    private user: any = {}
    private year: any = []
    private rates: any = []
    private dialog: boolean = false
    private rate: any = {}
    private result: any = null
    private form: any = {}
    private formResult: any = []
    private formUpdate: any = null

    private urlList: any = null
    private urlResponse: boolean = true
    private url: any = []
    private urlForm: any = {}

    private rateDatas: any = []
    private response: boolean = false
    resultResponse: boolean = false
    group: any = []
    scroll: number = 0
    
    resultData: any = []

    public async created() {

        await this.run()
        window.addEventListener("scroll", () => {
            this.scroll = window.scrollY;
        });

    }
    rateStatus: any = []
    private async run() {
        let loader = await this.$loading.show()
        this.response = false;
        this.user = await User.getUser();
        this.CURRNT_AGENGY = this.user.ext_link.agency
        this.AGENCY_DATA = await Core.getHttp(`api/ita/v2/agency/${this.CURRNT_AGENGY}/`)
        this.year = await Core.getHttp(`/api/ita/v2/year/${this.$route.query.year}/`)
        this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.$route.query.year}/`)
        this.result = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.CURRNT_AGENGY}&rate__year=${this.$route.query.year}`)
        this.rateStatus = await Core.getHttp(`/api/ita/v1/ratestatus/`)
        await this.generateTable();
        await this.genGroup()
        await this.getResult(this.CURRNT_AGENGY)
        this.response = true;
        await loader.hide()

    }
    i: any = 1
    dataIndex(index: any) {
        return index
    }

    private async genGroup() {
        this.group = await _.chain(this.rates)
            .groupBy("type_base")
            .map((value, key) => ({
                type_base: key, 
                base_data: _.chain(value)
                    .groupBy("type")
                    .map((value, key) => ({
                        type: key,
                        type_sub: value.length > 0 ? value[0].type_sub : null,
                        data: value
                    }))
                    .value()
            }))
            .value()

    }

    private async generateTable() {
        for (let i = 0; i < this.rates.length; i++) {
            console.log(this.rates[i].id);
            let result: any = _.filter(this.result, {
                'rate': this.rates[i].id
            })
            this.rates[i].result = result
            this.formResult[i] = {
                name: this.rates[i].name,
                rate: this.rates[i].id,
                user: this.user.pk,
                agency: this.CURRNT_AGENGY,
                register_type: ""
            }

        }
    }

    async storeResult(form: any, index: number) {
        if (this.formResult[index].register_type) {
            let scroll = this.scroll
            let loader = await this.$loading.show()
            let data = await Core.postHttp(`/api/ita/v1/rateresult/`, form)
            if (data.id) {
                alert('บันทึกข้อมูลสำเร็จแล้ว')
                this.formResult[index].urls = ''
                this.formResult[index].register_type = null
                this.formResult[index].ref = ''
                await this.loadResult(data.rate, index)
            } else {
                alert("กรุณาตรวจสอบข้อมูลให้ถูกต้อง")
            }

            loader.hide()
            window.scrollTo(0, scroll);
        } else {
            alert("กรุณาระบุสถานะของข้อมูล")
        }

    }

    public async openDialog(rate: any) {

        await this.loadRateData(rate);
        this.dialog = true;

    }
    private async loadRateData(rate: any) {
        this.rate = await Core.getHttp(`/api/ita/v1/rate/${rate.id}/`)
        this.form.rate = rate.id
        this.form.name = rate.name
        this.rateDatas = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.CURRNT_AGENGY}&rate=${this.rate.id}`)
        console.log(this.rate)
        this.resultResponse = true;
    }

    public async closeDialog() {
        this.rate = null
        this.form = {}
        this.dialog = false;
        this.resultResponse = false;
    }

    private async loadResult(id: number, index: number) {
        this.response = false;
        this.rates[index].result = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.CURRNT_AGENGY}&rate__year=${this.$route.query.year}&rate=${id}`)

        this.response = true;
    }

    private async saveData() {
        this.form.user = this.user.pk
        this.form.agency = this.CURRNT_AGENGY
        let data = await Core.postHttp(`/api/ita/v1/rateresult/`, this.form)
        if (data.id) {
            alert('บันทึกข้อมูลสำเร็จแล้ว')
            this.form = {}
            await this.loadRateData(this.rate)
        }
        await this.run()
    }

    private async getResultByUser(i: number) {
        return await Core.putHttp(`/api/ita/v2/rateresult/`, {
            "agency": this.CURRNT_AGENGY,
            "rate": this.rates[i].id
        })
    }

    private async updateData(data: any, index: number) {
        let loader = await this.$loading.show()
        let scroll = this.scroll
        let update = await Core.putHttp(`/api/ita/v1/rateresult/${data.id}/`, data)
        if (update.id) {
            alert('แก้ไขข้อมูลสำเร็จ')
            await this.loadResult(data.rate, index)
        }
        loader.hide()
        window.scrollTo(0, scroll);
    }

    private async removeData(data: any, index: number) {
        let scroll = this.scroll
        let check = confirm("คุณแน่ใจใช่ไหมที่จะลบรายการนี้")
        let loader = await this.$loading.show()
        if (check) {
            let remove = await Core.deleteHttp(`/api/ita/v1/rateresult/${data.id}/`)
            await this.loadResult(data.rate, index)
        }
        loader.hide()
        window.scrollTo(0, scroll);

    }

    private async passingStore(data: any) {
        let form = {
            "user_passing": this.user.pk,
            "passing": true
        }
        let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
        if (store.id) {
            console.log('ยืนยันการส่งสำเร็จ');
        }
    }

    private async passingAllStore(datas: any[]) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะส่งเนื้อหานี้ให้กับผู้ตรวจ")
        let scroll = this.scroll
        let loader = await this.$loading.show()
        if (check) {
            for (let i = 0; i < datas.length; i++) {
                await this.passingStore(datas[i])
            }
            await this.run()
        }
        loader.hide()
        window.scrollTo(0, scroll);
    }

    private async removePassingStore(data: any) {

        let form = {
            "user_passing": this.user.pk,
            "passing": false
        }
        let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
        if (store.id) {
            console.log('ยกเลิก');
        }

    }

    private async removePassingAllStore(datas: any[]) {

        let check = confirm("คุณแน่ใจใช่ไหมที่จะยกเลิกส่งเนื้อหานี้ให้กับผู้ตรวจ")
        if (check) {
            for (let i = 0; i < datas.length; i++) {
                await this.removePassingStore(datas[i])
            }
             await this.run()
            await this.loadRateData(this.rate)
            
        }
    }

    private passingDisableAdd(datas: any[]) {
        if (datas.length > 0) {
            let passed = _.filter(datas, {
                passing: false
            })
            console.log(passed);
            return (passed.length > 0) ? true : false;
        } else {
            return true;
        }
    }

    private passingAllCheck(datas: any[]) {

        if (datas.length > 0) {
            let passed = _.filter(datas, {
                passing: false
            })
            console.log(passed);
            return (passed.length > 0) ? true : false;
        } else {
            return false;
        }

    }

    private passingAllCheckTrue(datas: any[]) {

        if (datas.length > 0) {
            let passed = _.filter(datas, {
                passing: true
            })
            //console.log(passed.length , datas);
            return (passed.length > 0) ? true : false;
        } else {
            return false;
        }

    }

    private openLink(url: string) {
        window.open(url, '_blank');
    }
    
    private async getResult(agency: number) {
        let data = await Core.getHttp(`/api/oit/v1/evaluateoit/?agency=${agency}`)
        if(data){
            this.resultData = data
        }
    }

    genResult(id:any){
        return _.find(this.resultData, {
            'rate': id
        })
    }

    getPassingTest(result: any) {
        let res = _.filter(result, {
            'rate': 4
        })
        return res.length;
    }

    getTest(result: any) {
        let res = _.filter(result, function (o) {
            return o.tester != null;
        })
        return res.length;
    }

}
